<template>
  <ion-page>
    <ion-tabs v-if="$auth.check()">
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <ion-router-outlet />
      <ion-tab-bar
        slot="bottom"
        translucent="true"
      >
        <ion-tab-button
          tab="projects"
          href="/projecten"
        >
          <ion-icon name="file-tray-full-outline" />
          <ion-label>Projecten</ion-label>
        </ion-tab-button>
        <ion-tab-button
          tab="weighings"
          href="/wegingen"
        >
          <ion-icon name="speedometer-outline" />
          <ion-label>Wegingen</ion-label>
        </ion-tab-button>
        <ion-tab-button @click="openRequest">
          <ion-icon name="add-circle-outline" />
          <ion-label>Afroepen</ion-label>
        </ion-tab-button>
        <ion-tab-button
          tab="contact"
          href="/contact"
        >
          <ion-icon name="call-outline" />
          <ion-label>Contact</ion-label>
        </ion-tab-button>
        <ion-tab-button
          tab="profile"
          href="/profiel"
        >
          <ion-icon name="person-circle-outline" />
          <ion-label>Profiel</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage,
} from '@ionic/vue';
import ModalMixin from '@/mixins/ModalMixin';
import Request from '@/modals/Request.vue';

export default {
  components: {
    IonLabel,
    IonRouterOutlet,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
  },
  mixins: [ModalMixin],
  methods: {
    /**
     * Open request modal.
     */
    async openRequest() {
      await this.openModal(Request);
    },
  },
};
</script>
