import { createRouter, createWebHistory } from '@ionic/vue-router';
import { trackRouter } from 'vue-gtag-next';
import routes from '@/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

trackRouter(router);

export default router;
