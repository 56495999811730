<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/projecten"
            text=""
            icon="arrow-back"
            color="secondary"
          />
        </ion-buttons>
        <ion-title>Wegingen</ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh"
      >
        <ion-refresher-content />
      </ion-refresher>

      <section
        v-if="item.project.type !== 'yearly'"
        class="section-light ion-padding"
      >
        <ion-card-subtitle>{{ item.project.reference }}</ion-card-subtitle>
        <ion-card-title>{{ item.project.name }}</ion-card-title>
      </section>

      <section class="section-light ion-padding">
        <ion-card-subtitle>{{ item.waste_tracking_no }}</ion-card-subtitle>
        <ion-card-title>{{ item.product.name }}</ion-card-title>
        <ion-button
          size="small"
          color="dark"
          class="ion-no-margin ion-margin-top ion-margin-bottom"
        >
          <ion-icon
            slot="start"
            name="location-outline"
          />
          {{ item.location.name }}
        </ion-button>
        <ion-item
          class="ion-no-padding text-sm"
          lines="full"
        >
          <ion-label
            class="ion-no-margin"
            color="dark"
          >
            Totaal
          </ion-label>
          <ion-note
            slot="end"
            class="ion-no-margin"
            color="dark"
          >
            <b><balance
              v-if="item.total_weight_used"
              :used="item.total_weight_used"
            /></b>
          </ion-note>
        </ion-item>
      </section>

      <ion-card
        v-for="weighing in item.weighings"
        :key="`project-weighing-${weighing.id}`"
      >
        <ion-card-content>
          <ion-card-subtitle>{{ weighing.reference }}</ion-card-subtitle>
          <ion-card-title>Weging</ion-card-title>
          <ion-list class="ion-no-margin">
            <ion-item
              class="ion-no-padding text-sm"
              lines="full"
            >
              <ion-label
                class="ion-no-margin"
                color="dark"
              >
                Datum en tijd
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="dark"
              >
                <b>
                  {{ formatDateTime(weighing.created_at, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    minute: 'numeric',
                    hour: 'numeric',
                  }) }}
                </b>
              </ion-note>
            </ion-item>
            <ion-item
              class="ion-no-padding text-sm"
              lines="full"
            >
              <ion-label
                class="ion-no-margin"
                color="dark"
              >
                Kenteken
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="dark"
              >
                <b>{{ weighing.license_plate }}</b>
              </ion-note>
            </ion-item>
            <ion-item
              class="ion-no-padding text-sm"
              lines="full"
            >
              <ion-label
                class="ion-no-margin"
                color="dark"
              >
                Hoeveelheid
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="dark"
              >
                <b>{{ formatNumber(weighing.total_weight) }} ton</b>
              </ion-note>
            </ion-item>
            <ion-item
              class="ion-no-padding text-sm"
              :lines="weighing.remark && weighing.remark.length ? 'bottom' : 'none'"
            >
              <ion-label
                class="ion-no-margin"
                color="dark"
              >
                Weegbonnummer
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="dark"
              >
                <b>{{ weighing.reference }}</b>
              </ion-note>
            </ion-item>
            <ion-item
              v-if="weighing.remark && weighing.remark.length"
              class="ion-no-padding text-sm"
              lines="none"
            >
              <ion-label
                class="ion-no-margin"
                color="dark"
              >
                Opmerking
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="dark"
              >
                <b>{{ weighing.remark }}</b>
              </ion-note>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonRefresherContent,
  IonRefresher,
  IonIcon,
  IonButton,
  IonPage,
} from '@ionic/vue';
import Balance from '@/components/Balance.vue';
import ToastMixin from '@/mixins/ToastMixin';
import IntlMixin from '@/mixins/IntlMixin';

export default {
  components: {
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonThumbnail,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonRefresherContent,
    IonRefresher,
    IonIcon,
    IonButton,
    IonPage,
    Balance,
  },
  mixins: [
    IntlMixin,
    ToastMixin,
  ],
  props: {
    projectDetails: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      item: {
        project: {},
        product: {},
        location: {},
        weighings: null,
        total_weight_used: null,
      },
    };
  },
  async created() {
    await this.fetch();
  },
  methods: {
    /**
     * Fetch product details.
     */
    async fetch() {
      try {
        const result = await this.$http.get(`projects/${this.$route.params.project}/products/${this.$route.params.product}`, {
          params: {
            start: this.$route.query.startDate,
            end: this.$route.query.endDate,
          },
        });
        this.item = result.data;
      } catch {
        await this.showErrorToast('Fout bij het ophalen van de wegingen');
      }
    },
    /**
     * Refresh.
     *
     * @param {Event} e
     */
    async refresh(e) {
      await this.fetch();
      e.target.complete();
    },
  },
};
</script>

<style scoped>
  ion-item {
    --background: transparent;
    --inner-padding-end: 0 !important;
  }

  .ion-no-margin-top {
    margin-top: 0 !important;
  }
</style>
