<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/projecten"
            text=""
            icon="arrow-back"
            color="secondary"
          />
        </ion-buttons>
        <ion-title>Project</ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh"
      >
        <ion-refresher-content />
      </ion-refresher>

      <section class="section-light ion-padding">
        <template v-if="!item.project">
          <ion-skeleton-text
            style="width: 20%;"
            animated
          />
          <ion-skeleton-text
            style="width: 70%;"
            animated
          />
        </template>
        <template v-else>
          <ion-card-subtitle>{{ item.project.reference }}</ion-card-subtitle>
          <ion-card-title>{{ item.project.name }}</ion-card-title>
        </template>

        <ion-list class="ion-no-margin">
          <ion-item class="ion-no-padding text-sm">
            <ion-label color="dark">
              Laatste weging
            </ion-label>
            <ion-note
              v-if="item.project"
              slot="end"
              color="dark"
              class="ion-no-margin"
            >
              <template v-if="item.project.latest_weighing">
                {{ formatDateTime(item.project.latest_weighing.created_at) }} -
                {{ item.project.latest_weighing.project_product.location.name }}
              </template>
              <template v-else>
                onbekend
              </template>
            </ion-note>
            <ion-skeleton-text
              v-else
              slot="end"
              style="width: 20%;"
              animated
            />
          </ion-item>

          <ion-item
            class="ion-no-padding text-sm"
            lines="none"
          >
            <img
              alt="outgoing"
              src="/img/icon-outgoing.svg"
              class="ion-margin-end"
            >
            <ion-label class="ion-no-margin">
              Totaal gehaald
            </ion-label>
            <ion-note
              v-if="item.stats"
              slot="end"
              class="ion-no-margin"
              color="black"
            >
              <b><balance
                :used="item.stats.outgoing"
                :total="item.stats.outgoing_total"
              /></b>
            </ion-note>
            <ion-skeleton-text
              v-else
              slot="end"
              style="width: 20%;"
              animated
            />
          </ion-item>
          <ion-item
            class="ion-no-padding progress-bar"
            lines="none"
          >
            <progress-bar
              :used="item.stats.outgoing"
              :total="item.stats.outgoing_total"
            />
          </ion-item>
          <ion-item
            class="ion-no-padding text-sm"
            lines="none"
          >
            <img
              alt="incoming"
              src="/img/icon-incoming.svg"
              class="ion-margin-end"
            >
            <ion-label class="ion-no-margin">
              Totaal gestort
            </ion-label>
            <ion-note
              v-if="item.stats"
              slot="end"
              class="ion-no-margin"
              color="black"
            >
              <b><balance
                :used="item.stats.incoming"
                :total="item.stats.incoming_total"
              /></b>
            </ion-note>
            <ion-skeleton-text
              v-else
              slot="end"
              style="width: 20%;"
              animated
            />
          </ion-item>
          <ion-item
            class="ion-no-padding progress-bar"
            lines="none"
          >
            <progress-bar
              :used="item.stats.incoming"
              :total="item.stats.incoming_total"
            />
          </ion-item>
        </ion-list>
        <ion-row
          v-if="item.project"
          class="align-content-stretch ion-margin-top"
        >
          <ion-col class="ion-no-padding">
            <ion-button
              expand="block"
              color="light"
              @click="openReport"
            >
              <ion-icon
                slot="start"
                name="stats-chart-outline"
              />
              Rapportage
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding">
            <ion-button
              v-if="item.project.status === 'archived'"
              expand="block"
              color="light"
              @click="openCertificates"
            >
              <ion-icon
                slot="start"
                name="ribbon-outline"
              />
              Certificaten
            </ion-button>
            <ion-button
              v-else
              expand="block"
              color="light"
              :disabled="item.project.status === 'pending_completion'"
              @click="openArchiveRequest"
            >
              <ion-icon
                slot="start"
                name="archive-outline"
              />
              Afrondverzoek
            </ion-button>
          </ion-col>
        </ion-row>
      </section>

      <section class="ion-padding-bottom">
        <div class="segment">
          <ion-segment
            v-model="section"
            scrollable
          >
            <ion-segment-button value="outgoing">
              <ion-label>Halen</ion-label>
            </ion-segment-button>
            <ion-segment-button value="incoming">
              <ion-label>Storten</ion-label>
            </ion-segment-button>
            <ion-segment-button
              value="communication"
              layout="icon-end"
            >
              <ion-label>Communicatie</ion-label>
              <ion-icon
                v-if="item.project && item.project.unperceived_comments > 0"
                name="alert-circle"
                size="small"
                class="ion-no-margin"
                color="warning"
              />
            </ion-segment-button>
          </ion-segment>
        </div>

        <template v-if="section === 'communication'">
          <section class="ion-padding-horizontal">
            <p class="text-sm">
              <ion-text color="dark">
                {{ item.project.description }}
              </ion-text>
            </p>
          </section>
          <project-comments :id="item.project.id" />
        </template>
        <project-products
          v-else-if="item.project.id"
          :id="item.project.id"
          :type="section"
          weighing-route="project-weighing"
          :requestable="item.project.status === 'open'"
        />
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/vue';
import Balance from '@/components/Balance.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import ProjectComments from '@/components/ProjectComments.vue';
import ProjectProducts from '@/components/ProjectProducts.vue';
import IntlMixin from '@/mixins/IntlMixin';
import ModalMixin from '@/mixins/ModalMixin';
import ShowMixin from '@/mixins/ShowMixin';
import ArchiveRequest from '@/modals/ArchiveRequest.vue';
import Certificates from '@/modals/Certificates.vue';
import Report from '@/modals/Report.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonThumbnail,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonSkeletonText,
    IonCardSubtitle,
    IonCardTitle,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonText,
    ProgressBar,
    Balance,
    ProjectProducts,
    ProjectComments,
  },
  mixins: [
    ModalMixin,
    IntlMixin,
    ShowMixin((id) => `projects/${id}`),
  ],
  data() {
    return {
      section: 'outgoing',
      item: {
        project: {},
        stats: {
          outgoing: 0,
          outgoing_total: 0,
          incoming: 0,
          incoming_total: 0,
        },
      },
    };
  },
  methods: {
    /**
     * Refresh.
     *
     * @param {Event} e
     */
    refresh(e) {
      setTimeout(() => {
        e.target.complete();
      }, 1000);
    },
    /**
     * Open report modal.
     */
    async openReport() {
      await this.openModal(Report, {
        project: this.item.project,
      });
    },
    /**
     * Open archive request modal.
     */
    async openArchiveRequest() {
      await this.openModal(ArchiveRequest, {
        id: parseInt(this.id, 10),
      });
      this.onUpdate(this.id);
    },
    /**
     * Open certificates modal.
     */
    async openCertificates() {
      await this.openModal(Certificates, {
        project: this.item.project,
      });
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: #bcb6ab;
}

.section-light {
  background: #ece9e3;
}

ion-list {
  background: none !important;
}

ion-item {
  --background: none;
  --inner-padding-end: 0 !important;
}

ion-row {
  width: 100%;
}

.segment {
  position: sticky;
  z-index: 999;
  top: 0;
  background: #bcb6ab;
}

.ios {
  .segment {
    padding: 8px;

    ion-segment {
      --background: #646464;
    }

    ion-segment-button {
      --color: #fff;
      --color-checked: #000;
    }
  }
}
</style>
