<template>
  <div id="comments-list">
    <template v-if="comments === null">
      <ion-card
        v-for="index in 10"
        :key="`comment-skeleton-${index}`"
      >
        <ion-card-header>
          <ion-card-subtitle>
            <ion-skeleton-text
              style="width: 60%;"
              animated
            />
          </ion-card-subtitle>
          <ion-card-title>
            <ion-skeleton-text
              style="width: 40%;"
              animated
            />
          </ion-card-title>
        </ion-card-header>
      </ion-card>
    </template>
    <ion-card
      v-for="c in comments"
      v-else
      :key="`project-comment-${c.id}`"
      :ref="c.perceived || c.user_id === $auth.user().id ? 'perceived' : 'unperceived'"
      :data-id="c.id"
    >
      <ion-card-header>
        <ion-card-subtitle>{{ formatDateTime(c.created_at) }}</ion-card-subtitle>
        <ion-card-title>
          {{ c.user.name }}
          <template v-if="c.author_type === 'user'">
            - Twee “R”
          </template>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>
          {{ c.text }}
        </p>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <form
        ref="comment"
        @submit.prevent="valid && create()"
      >
        <ion-list>
          <ion-item lines="none">
            <ion-label
              position="stacked"
              class="ion-text-wrap"
            >
              Bericht plaatsen
            </ion-label>
            <ion-textarea
              v-model="comment"
              auto-grow="true"
              required
              placeholder="Jouw bericht..."
            />
          </ion-item>
        </ion-list>
        <ion-button
          type="submit"
          :disabled="!valid"
          expand="full"
          color="secondary"
          class="ion-no-margin"
        >
          Bericht plaatsen
        </ion-button>
      </form>
    </ion-card>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonSkeletonText,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
} from '@ionic/vue';
import IntlMixin from '@/mixins/IntlMixin';
import ToastMixin from '@/mixins/ToastMixin';

let debouncer;
let observer;
let perceived = [];

export default defineComponent({
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonSkeletonText,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonButton,
  },
  mixins: [
    IntlMixin,
    ToastMixin,
  ],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      comments: null,
      comment: null,
    };
  },
  computed: {
    valid() {
      return this.comment && this.comment.length;
    },
  },
  async created() {
    await this.fetch();
    if (this.$refs.unperceived) {
      this.observe();
    }
  },
  methods: {
    /**
     * Fetch comments list
     */
    async fetch() {
      const result = await this.$http.get(`projects/${this.id}/comments`);
      this.comments = result.data;
    },
    /**
     * Create comment
     */
    async create() {
      const request = {
        text: this.comment,
      };
      await this.$http.post(`projects/${this.id}/comments`, request);
      this.comment = null;
      await this.showSuccessToast('Verzenden gelukt');
      await this.fetch();
    },
    /**
     * Start comments observer
     */
    observe() {
      const options = {
        root: document.querySelector('ion-content'),
        rootMargin: '20px',
        threshold: 0,
      };
      observer = new IntersectionObserver(this.perceivedCallback, options);
      let { unperceived } = this.$refs;
      /* c8 ignore next 3 */
      if (!Array.isArray(unperceived)) {
        unperceived = [unperceived];
      }
      unperceived.forEach((comment) => {
        observer.observe(comment);
      });
    },
    /**
     * Comments observe callback
     *
     * @param {Array} comments
     */
    /* c8 ignore start */
    perceivedCallback(comments) {
      comments.forEach((comment) => {
        if (comment.isIntersecting) {
          perceived.push(parseInt(comment.target.dataset.id, 10));
          observer.unobserve(comment.target);
        }
      });

      clearTimeout(debouncer);
      if (perceived.length) {
        debouncer = setTimeout(async () => {
          const selection = [...perceived];
          const request = { comments: selection };
          await this.$http.post(`projects/${this.id}/comments/perceived`, request);
          perceived = perceived.filter((p) => !selection.includes(p));
        }, 500);
      }
    },
  },
  /* c8 ignore stop */
});
</script>
