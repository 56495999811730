<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Selecteer product</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <ion-searchbar
        v-model="params.search"
        inputmode="search"
        placeholder="Zoek naar producten"
      />

      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh"
      >
        <ion-refresher-content />
      </ion-refresher>

      <ion-card class="ion-no-margin ion-margin-horizontal">
        <ion-card-content class="ion-no-padding">
          <ion-list
            v-if="items === null"
            class="ion-no-padding"
          >
            <ion-item
              v-for="index in 20"
              :key="`project-skeleton-${index}`"
              class="ion-no-padding"
            >
              <ion-label class="ion-text-wrap ion-margin-vertical">
                <ion-card-subtitle>
                  <ion-skeleton-text
                    style="width: 25%;"
                    animated
                  />
                </ion-card-subtitle>
                <b>
                  <ion-skeleton-text
                    style="width: 90%;"
                    animated
                  />
                  <ion-skeleton-text
                    style="width: 80%;"
                    animated
                  />
                </b>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            v-else-if="items && items.length"
            class="ion-no-padding"
          >
            <ion-radio-group
              :value="value ? value.id : null"
              @ionChange="onChange"
            >
              <ion-item
                v-for="(product, index) in items"
                :key="`product-${product.id}`"
                :lines="index === (items.length - 1) ? 'none' : 'full'"
                class="ion-no-padding"
              >
                <ion-radio
                  slot="start"
                  :value="product.id"
                  color="success"
                  class="ion-margin-start"
                />
                <ion-label class="ion-text-wrap ion-margin-vertical">
                  <ion-card-subtitle>{{ product.reference }}</ion-card-subtitle>
                  <b>{{ product.name }}</b>
                </ion-label>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <ion-list
            v-else
            lines="none"
          >
            <ion-item>Geen producten</ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-infinite-scroll @ionInfinite="load">
        <ion-infinite-scroll-content />
      </ion-infinite-scroll>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          color="secondary"
          expand="block"
          @click="closeModal(result)"
        >
          Opslaan
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonCardSubtitle,
  IonSkeletonText,
  IonRadioGroup,
  IonRadio,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFooter,
} from '@ionic/vue';
import DataMixin from '@/mixins/DataMixin';
import ModalMixin from '@/mixins/ModalMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonSearchbar,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonCardSubtitle,
    IonSkeletonText,
    IonRadioGroup,
    IonRadio,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFooter,
  },
  mixins: [DataMixin, ModalMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    projectId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      endpoint: 'products',
      params: {
        type: this.type,
      },
      checked: {},
      result: this.value,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.result = value;
      },
    },
    type: {
      handler(value) {
        this.params.type = value;
      },
    },
  },
  methods: {
    /**
     * Handle value values on change.
     *
     * @param {Event} event
     */
    onChange(event) {
      this.result = this.items.find((item) => item.id === +event.detail.value);
    },
  },
};
</script>
