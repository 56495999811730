<template>
  <div>
    <ion-card class="ion-margin ion-margin-horizontal">
      <ion-card-content class="ion-no-padding">
        <ion-list
          v-if="items === null"
          class="ion-no-padding"
        >
          <ion-item
            v-for="index in 20"
            :key="`project-skeleton-${index}`"
            class="ion-no-padding"
          >
            <ion-label class="ion-text-wrap ion-padding-start ion-margin-vertical">
              <ion-skeleton-text
                style="width: 25%;"
                animated
              />
            </ion-label>
          </ion-item>
        </ion-list>
        <ion-list
          v-else-if="items.length"
          class="ion-no-padding"
        >
          <ion-item
            v-for="user in items"
            :key="`user-${user.id}`"
            class="ion-no-padding"
            button
            @click="$router.push({ name: 'user', params: { id: user.id } })"
          >
            <ion-label class="ion-padding-start">
              {{ user.name }}
            </ion-label>
          </ion-item>
        </ion-list>
        <ion-list
          v-else
          lines="none"
        >
          <ion-item>Geen gebruikers</ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <ion-button
      class="ion-padding-horizontal ion-no-margin"
      color="secondary"
      expand="block"
      @click="openUser"
    >
      Gebruiker toevoegen
    </ion-button>
    <ion-infinite-scroll @ionInfinite="load">
      <ion-infinite-scroll-content />
    </ion-infinite-scroll>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';
import DataMixin from '@/mixins/DataMixin';
import ModalMixin from '@/mixins/ModalMixin';
import User from '@/modals/User.vue';

export default {
  components: {
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  mixins: [DataMixin, ModalMixin],
  data() {
    return {
      endpoint: 'users',
    };
  },
  methods: {
    /**
     * Open user modal.
     */
    async openUser() {
      const result = await this.openModal(User);

      if (result && result.id) {
        this.page = 0;
        this.items = await this.fetch();
      }
    },
  },
};
</script>
