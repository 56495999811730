<template>
  <ion-card>
    <ion-img src="/img/bg.jpg" />
    <ion-card-header>
      <ion-card-subtitle>Locatie</ion-card-subtitle>
      <ion-card-title>{{ location.name }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p>{{ location.address }}</p>
      <p>{{ location.zipcode }} {{ location.name }}</p>
      <ion-button
        size="small"
        color="tertiary"
        :href="route"
        target="_blank"
        rel="noopener noreferrer"
        class="ion-margin-top"
      >
        <ion-icon
          slot="start"
          name="navigate-circle-outline"
        />
        Routebeschrijving
      </ion-button><br>
      <ion-button
        size="small"
        color="tertiary"
        :href="`mailto:${location.email}`"
      >
        <ion-icon
          slot="start"
          name="mail-outline"
        />
        {{ location.email }}
      </ion-button><br>
      <ion-button
        size="small"
        color="tertiary"
        :href="`tel:${location.phone}`"
        class="ion-margin-bottom"
      >
        <ion-icon
          slot="start"
          name="call-outline"
        />
        {{ location.phone }}
      </ion-button>

      <ion-card-subtitle class="ion-margin-top">
        Contactpersoon
      </ion-card-subtitle>
      <ion-button
        size="small"
        color="tertiary"
        :href="`mailto:${location.contact_email}`"
      >
        <ion-icon
          slot="start"
          name="mail-outline"
        />
        {{ location.contact_email }}
      </ion-button><br>
      <ion-button
        size="small"
        color="tertiary"
        :href="`tel:${location.contact_phone}`"
        class="ion-margin-bottom"
      >
        <ion-icon
          slot="start"
          name="call-outline"
        />
        {{ location.contact_phone }}
      </ion-button>

      <ion-card-subtitle class="ion-margin-top">
        Commercieel contactpersoon
      </ion-card-subtitle>
      <ion-button
        size="small"
        color="tertiary"
        :href="`mailto:${location.sales_email}`"
      >
        <ion-icon
          slot="start"
          name="mail-outline"
        />
        {{ location.sales_email }}
      </ion-button><br>
      <ion-button
        size="small"
        color="tertiary"
        :href="`tel:${location.sales_phone}`"
      >
        <ion-icon
          slot="start"
          name="call-outline"
        />
        {{ location.sales_phone }}
      </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonImg,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
} from '@ionic/vue';

export default {
  components: {
    IonCard,
    IonImg,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonIcon,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  computed: {
    route() {
      return encodeURI(`https://maps.google.nl/maps?daddr=${this.location.address}+${this.location.zipcode}+${this.location.city}`);
    },
  },
};
</script>

<style scoped>
ion-img {
  height: 200px;
  object-fit: cover;
}
</style>
