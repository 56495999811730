<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-title slot="start">
          Profiel
        </ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
      <ion-toolbar v-if="$auth.user().admin">
        <ion-segment v-model="section">
          <ion-segment-button value="profile">
            <ion-label>Mijn profiel</ion-label>
          </ion-segment-button>
          <ion-segment-button value="users">
            <ion-label>Gebruikers</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <template v-if="section === 'profile'">
        <ion-card
          class="ion-margin"
          button
          @click="openUser"
        >
          <ion-card-header>
            <ion-row class="ion-justify-content-between ion-align-items-center">
              <ion-card-title>{{ $auth.user().name }}</ion-card-title>
              <ion-chip
                v-if="$auth.user().admin"
                class="ion-no-margin"
                color="primary"
              >
                <ion-label>Admin</ion-label>
              </ion-chip>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <p>{{ $auth.user().email }}</p>
            <p>{{ $auth.user().phone }}</p>
          </ion-card-content>
        </ion-card>
        <ion-button
          class="ion-padding-horizontal ion-no-margin"
          color="secondary"
          expand="block"
          @click="$auth.logout()"
        >
          <ion-icon
            slot="start"
            name="log-out-outline"
          />
          Uitloggen
        </ion-button>
      </template>

      <users v-else-if="section === 'users'" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonContent,
  IonCard,
  IonCardHeader,
  IonRow,
  IonCardTitle,
  IonChip,
  IonCardContent,
  IonButton,
  IonIcon,
} from '@ionic/vue';
import Users from '@/components/Users.vue';
import ModalMixin from '@/mixins/ModalMixin';
import User from '@/modals/User.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonThumbnail,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonContent,
    IonCard,
    IonCardHeader,
    IonRow,
    IonCardTitle,
    IonChip,
    IonCardContent,
    IonButton,
    IonIcon,
    Users,
  },
  mixins: [ModalMixin],
  data() {
    return {
      section: 'profile',
    };
  },
  methods: {
    /**
     * Open user modal.
     */
    async openUser() {
      const result = await this.openModal(User, {
        user: this.$auth.user(),
      });

      if (result && result.id) {
        this.$auth.user(result);
      }
    },
  },
};
</script>
