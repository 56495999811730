import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Ionicons */
import { addIcons } from 'ionicons';
import {
  addCircleOutline,
  callOutline,
  mailOutline,
  personCircleOutline,
  searchOutline,
  searchSharp,
  closeCircle,
  close,
  arrowBack,
  arrowForward,
  alertCircle,
  logInOutline,
  logOutOutline,
  locationOutline,
  statsChartOutline,
  timeOutline,
  createOutline,
  archiveOutline,
  chevronForward,
  checkmarkCircleOutline,
  navigateCircleOutline,
  informationCircle,
  ribbonOutline,
  speedometerOutline,
  fileTrayFullOutline,
  cloudDownloadOutline,
  chevronDownCircle,
  chevronUpCircle,
} from 'ionicons/icons';

addIcons({
  'add-circle-outline': addCircleOutline,
  'call-outline': callOutline,
  'mail-outline': mailOutline,
  'person-circle-outline': personCircleOutline,
  'search-outline': searchOutline,
  'search-sharp': searchSharp,
  'close-circle': closeCircle,
  'arrow-back': arrowBack,
  'arrow-forward': arrowForward,
  'alert-circle': alertCircle,
  'log-in-outline': logInOutline,
  'log-out-outline': logOutOutline,
  'location-outline': locationOutline,
  'stats-chart-outline': statsChartOutline,
  'archive-outline': archiveOutline,
  'chevron-forward': chevronForward,
  'checkmark-circle-outline': checkmarkCircleOutline,
  'time-outline': timeOutline,
  'create-outline': createOutline,
  'navigate-circle-outline': navigateCircleOutline,
  'information-circle': informationCircle,
  'ribbon-outline': ribbonOutline,
  'speedometer-outline': speedometerOutline,
  'file-tray-full-outline': fileTrayFullOutline,
  'cloud-download-outline': cloudDownloadOutline,
  'chevron-down-circle': chevronDownCircle,
  'chevron-up-circle': chevronUpCircle,
  close,
});

export default IonicVue;
