<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Locatie</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <location
        v-if="location.id"
        :location="location"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
} from '@ionic/vue';
import Location from '@/components/Location.vue';
import ModalMixin from '@/mixins/ModalMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    Location,
  },
  mixins: [ModalMixin],
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
};
</script>
