<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Gebruiker {{ user.id ? 'bewerken' : 'toevoegen' }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <ion-card>
        <form ref="form">
          <ion-list lines="full">
            <ion-item>
              <ion-label position="stacked">
                Voornaam
              </ion-label>
              <ion-input
                v-model="user.first_name"
                required
              />
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                Achternaam
              </ion-label>
              <ion-input v-model="user.last_name" />
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                E-mailadres
              </ion-label>
              <ion-input
                v-model="user.email"
                type="email"
                required
              />
            </ion-item>
            <ion-item :lines="canDelete ? 'full' : 'none'">
              <ion-label position="stacked">
                Mobiel telefoonnummer
              </ion-label>
              <ion-input
                v-model="user.phone"
                type="tel"
                required
              />
            </ion-item>
            <ion-item
              v-if="canDelete"
              lines="none"
              class="ion-margin-vertical"
            >
              <ion-toggle
                slot="start"
                v-model="shouldDelete"
                color="danger"
              />
              <ion-label class="text-sm">
                Gebruiker verwijderen
              </ion-label>
            </ion-item>
          </ion-list>
        </form>
      </ion-card>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          v-if="shouldDelete"
          color="danger"
          expand="block"
          @click="deleteUser"
        >
          Gebruiker verwijderen
        </ion-button>
        <ion-button
          v-else
          color="secondary"
          expand="block"
          @click="saveUser"
        >
          Gebruiker {{ user.id ? 'bewerken' : 'toevoegen' }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonToggle,
  IonFooter,
} from '@ionic/vue';
import ModalMixin from '@/mixins/ModalMixin';
import ToastMixin from '@/mixins/ToastMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonToggle,
    IonFooter,
  },
  mixins: [ModalMixin, ToastMixin],
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      shouldDelete: false,
    };
  },
  computed: {
    canDelete() {
      return this.user.id && this.user.id !== this.$auth.user().id && !this.user.admin;
    },
  },
  methods: {
    /**
     * Delete user.
     */
    async deleteUser() {
      try {
        const result = await this.$http.delete(`users/${this.user.id}`);
        await this.showSuccessToast('Verwijderen gelukt');
        await this.closeModal(result.data);
      } catch (e) {
        await this.showErrorToast('Verwijderen mislukt');
      }
    },
    /**
     * Save user.
     */
    async saveUser() {
      try {
        let result;
        if (this.user.id) {
          result = await this.$http.patch(`users/${this.user.id}`, this.user);
        } else {
          result = await this.$http.post('users', this.user);
        }
        await this.showSuccessToast('Opslaan gelukt');
        await this.closeModal(result.data);
      } catch (e) {
        await this.showErrorToast('Opslaan mislukt');
      }
    },
  },
};
</script>
