<template>
  <ion-page>
    <ion-content fixed>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <img
            alt="Twee “R”"
            src="/img/logo-full.svg"
          >
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card>
              <form @submit.prevent="login">
                <ion-list lines="full">
                  <ion-item>
                    <ion-label position="stacked">
                      E-mailadres
                    </ion-label>
                    <ion-input
                      v-model="email"
                      type="email"
                      required
                    />
                  </ion-item>
                  <ion-item>
                    <ion-label position="stacked">
                      Wachtwoord
                    </ion-label>
                    <ion-input
                      v-model="password"
                      type="password"
                      required
                    />
                  </ion-item>
                  <ion-item
                    style="--border-width: 0;"
                    class="ion-margin-bottom"
                  >
                    <ion-label>
                      <span
                        color="secondary"
                        class="text-sm"
                        @click="forgotPassword"
                      >
                        Wachtwoord vergeten?
                      </span>
                    </ion-label>
                  </ion-item>
                </ion-list>
                <ion-button
                  type="submit"
                  expand="full"
                  color="secondary"
                  class="ion-no-margin"
                >
                  <ion-icon
                    slot="start"
                    name="log-in-outline"
                  />
                  Inloggen
                </ion-button>
              </form>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  alertController,
} from '@ionic/vue';
import ToastMixin from '@/mixins/ToastMixin';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
  },
  mixins: [ToastMixin],
  data() {
    return {
      email: '',
      password: '',
      toastCssClass: undefined,
    };
  },
  watch: {
    '$route.query': {
      async handler(value) {
        if (value && value.token && value.email) {
          await this.resetPassword(value.token, value.email);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * Forgot password dialog.
     */
    async forgotPassword() {
      const alert = await alertController.create({
        header: 'Wachtwoord vergeten',
        message: 'Voer hier uw e-mailadres in en we sturen u instructies om uw wachtwoord te resetten.',
        inputs: [
          {
            placeholder: 'Vul uw e-mailadres in',
            type: 'email',
          },
        ],
        buttons: [
          {
            text: 'Annuleren',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Versturen',
            handler: this.handleForgotPassword,
          },
        ],
      });
      await alert.present();
    },
    /**
     * Handle forgot password action.
     *
     * @param {Object} input
     */
    async handleForgotPassword({ 0: email }) {
      try {
        const result = await this.$http.post('password/forgot', {
          email,
        });
        await this.showToast(result.data.message);
      } catch {
        await this.showErrorToast('Wachtwoord reset aanvraag mislukt');
      }
    },
    /**
     * Reset password dialog.
     *
     * @param {String} token
     * @param {String} email
     */
    async resetPassword(token, email) {
      const alert = await alertController.create({
        header: 'Wachtwoord resetten',
        message: 'Voer hier uw nieuwe wachtwoord in.',
        inputs: [
          {
            placeholder: 'Vul uw wachtwoord in',
            type: 'password',
          },
          {
            placeholder: 'Vul nogmaals uw wachtwoord in',
            type: 'password',
          },
        ],
        buttons: [
          {
            text: 'Annuleren',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Versturen',
            handler: this.handleResetPassword.bind(this, token, email),
          },
        ],
      });
      await alert.present();
    },
    /**
     * Handle reset password action.
     *
     * @param {String} token
     * @param {String} email
     * @param {Object} input
     */
    async handleResetPassword(token, email, input) {
      try {
        const result = await this.$http.post('password/reset', {
          email,
          password: input[0],
          password_confirmation: input[1],
          token,
        });
        await this.showSuccessToast(result.data.message);
      } catch {
        await this.showErrorToast('Wachtwoord reset mislukt');
      }
    },
    /**
     * Login.
     */
    async login() {
      try {
        await this.$auth.login({
          data: {
            email: this.email,
            password: this.password,
          },
          staySignedIn: true,
        });
      } catch {
        await this.showErrorToast('Inloggen mislukt');
      }
    },
  },
});
</script>

<style scoped>
ion-content {
  --background: none;

  background-image: url("/public/img/bg.jpg");
  background-position: center;
  background-size: cover;
}

ion-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100vh;
  background: rgb(0 0 0 / 50%);
}

ion-row {
  width: 100%;
}

span {
  text-decoration: underline;
}
</style>
