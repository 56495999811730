<template>
  <div>
    <template v-if="products === null">
      <ion-card
        v-for="index in 10"
        :key="`project-product-skeleton-${index}`"
      >
        <ion-card-content>
          <ion-card-title>
            <ion-skeleton-text
              style="width: 90%;"
              animated
            />
          </ion-card-title>
          <ion-card-subtitle>
            <ion-skeleton-text
              style="width: 50%;"
              animated
            />
          </ion-card-subtitle>
        </ion-card-content>
      </ion-card>
    </template>
    <template v-else-if="products.length">
      <ion-card
        v-for="item in products"
        :key="`project-product-${item.id}`"
      >
        <ion-card-content>
          <ion-card-subtitle>{{ item.waste_tracking_no }}</ion-card-subtitle>
          <ion-card-title>{{ item.product.name }}</ion-card-title>
          <ion-button
            size="small"
            color="tertiary"
            class="ion-no-margin ion-margin-top ion-margin-bottom"
            @click="openLocation(item.location)"
          >
            <ion-icon
              slot="start"
              name="location-outline"
            />
            {{ item.location.name }}
          </ion-button>
          <ion-list class="ion-no-margin">
            <ion-item
              class="ion-no-padding text-sm"
              lines="none"
            >
              <ion-label
                class="ion-no-margin"
                color="black"
              >
                Hoeveelheid
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="black"
              >
                <b><balance
                  :used="item.total_weight_used"
                  :total="item.total_weight"
                /></b>
              </ion-note>
            </ion-item>
            <ion-item
              v-if="item.total_weight"
              class="ion-no-padding progress-bar"
              lines="none"
            >
              <progress-bar
                :used="item.total_weight_used"
                :total="item.total_weight"
              />
            </ion-item>
            <ion-item
              v-if="item.analyses_received"
              class="ion-no-padding text-sm"
              lines="none"
            >
              <ion-chip
                color="success"
                outline
                class="ion-no-margin"
              >
                <ion-icon
                  color="success"
                  name="information-circle"
                />
                <ion-label
                  color="success"
                  class="text-sm"
                >
                  Analyse ingediend
                </ion-label>
              </ion-chip>
            </ion-item>
            <project-product-requests
              :requests="item.requests"
              :product="item.product"
              :type="type"
              @updated="fetch()"
            />
            <ion-item
              class="ion-no-padding text-sm"
              button
              lines="none"
              @click="$router.push({
                name: weighingRoute,
                params: {
                  project: id,
                  product: item.id,
                },
                query: {
                  type,
                  startDate,
                  endDate,
                },
              })"
            >
              <ion-label>
                <ion-card-title>Wegingen</ion-card-title>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
        <ion-button
          v-if="requestable"
          expand="full"
          color="secondary"
          class="ion-no-margin"
          @click="openRequest(item.id, item)"
        >
          <ion-icon
            slot="start"
            name="add-circle-outline"
          />
          Product afroepen
        </ion-button>
      </ion-card>
    </template>
    <ion-card v-else>
      <ion-card-content>
        Er zijn nog geen wegingen
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonSkeletonText,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonChip,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import Balance from '@/components/Balance.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import IntlMixin from '@/mixins/IntlMixin';
import ModalMixin from '@/mixins/ModalMixin';
import Location from '@/modals/Location.vue';
import Request from '@/modals/Request.vue';
import ProjectProductRequests from '@/components/ProjectProductRequests.vue';

export default {
  components: {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonSkeletonText,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonChip,
    IonIcon,
    IonButton,
    ProgressBar,
    ProjectProductRequests,
    Balance,
  },
  mixins: [
    ModalMixin,
    IntlMixin,
  ],
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    weighingRoute: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    requestable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      products: null,
    };
  },
  watch: {
    type: {
      async handler() {
        await this.fetch();
      },
      immediate: true,
    },
    /* c8 ignore start */
    startDate() {
      this.fetch();
    },
    endDate() {
      this.fetch();
    },
    /* c8 ignore stop */
  },
  methods: {
    /**
     * Fetch products list
     */
    async fetch() {
      const result = await this.$http.get(`projects/${this.id}/products`, {
        params: {
          type: this.type,
          start: this.startDate,
          end: this.endDate,
        },
      });
      this.products = result.data;
    },
    /**
     * Open location modal.
     *
     * @param {Object} location
     */
    async openLocation(location) {
      await this.openModal(Location, { location });
    },
    /**
     * Open request modal.
     *
     * @param {Number} id
     * @param {Object} projectProduct
     */
    async openRequest(id, projectProduct) {
      await this.openModal(Request, {
        type: this.type,
        location: projectProduct.location_id,
        projectProduct: id,
        project: {
          id: this.id,
        },
        product: projectProduct.product,
      });
      await this.fetch();
    },
  },
};
</script>

<style scoped>
  ion-item {
    --inner-padding-end: 0 !important;
  }

  .collapsed {
    ion-icon {
      transform: rotate(-90deg);
    }
  }
</style>
