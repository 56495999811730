<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Product afroepen</ion-title>
      </ion-toolbar>
      <ion-toolbar v-if="!project.id">
        <ion-segment v-model="request.section">
          <ion-segment-button value="projects">
            <ion-label>Op project</ion-label>
          </ion-segment-button>
          <ion-segment-button value="agreements">
            <ion-label>Losse weging</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <form ref="form">
        <ion-card v-show="!project.id && request.section === 'projects'">
          <ion-card-content class="ion-no-padding">
            <ion-list
              class="ion-no-padding"
              lines="none"
            >
              <ion-item
                class="ion-no-padding"
                button
                @click="openProjects"
              >
                <ion-label class="ion-padding-start">
                  <template v-if="request.project.id">
                    <ion-card-subtitle>{{ request.project.reference }}</ion-card-subtitle>
                    <b>{{ request.project.name }}</b>
                  </template>
                  <template v-else>
                    Selecteer een project
                  </template>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card v-if="!type">
          <ion-list
            class="ion-no-padding"
            lines="full"
          >
            <ion-radio-group
              v-model="request.type"
              @ionChange="changeType"
            >
              <ion-item>
                <ion-label>Halen</ion-label>
                <ion-radio
                  slot="start"
                  value="outgoing"
                />
              </ion-item>
              <ion-item lines="none">
                <ion-label>Storten</ion-label>
                <ion-radio
                  slot="start"
                  value="incoming"
                />
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-card>

        <ion-card
          v-if="!projectProduct"
          :disabled="!request.type"
        >
          <ion-card-content class="ion-no-padding">
            <ion-list
              class="ion-no-padding"
              lines="none"
            >
              <ion-item
                class="ion-no-padding"
                button
                @click="request.section === 'projects' ? openProjectProducts() : openProducts()"
              >
                <ion-label class="ion-padding-start">
                  <template v-if="selectedProduct.id">
                    <ion-card-subtitle>{{ selectedProduct.reference }}</ion-card-subtitle>
                    <b>{{ selectedProduct.name }}</b>
                  </template>
                  <template v-else>
                    Selecteer een product
                  </template>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card :disabled="!request.type">
          <ion-list lines="full">
            <template v-if="!item.id && product.cubic_factor > 0">
              <ion-item>
                <ion-label position="stacked">
                  Eenheid
                </ion-label>
                <ion-select
                  v-model="request.unit"
                  ok-text="Kiezen"
                  cancel-text="Annuleren"
                  required
                >
                  <ion-select-option
                    :value="1"
                    selected
                  >
                    Ton
                  </ion-select-option>
                  <ion-select-option :value="2">
                    Kubieke meters
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item v-if="request.unit === 2">
                <ion-label position="stacked">
                  Aantal kubieke meters
                </ion-label>
                <ion-input
                  v-model.number="request.total_cubic_meters"
                  type="number"
                  placeholder="Bijv. 123"
                  step="0.01"
                  required
                />
              </ion-item>
            </template>
            <ion-item>
              <ion-label position="stacked">
                Aantal ton
              </ion-label>
              <ion-input
                v-model.number="request.total_weight"
                type="number"
                placeholder="Bijv. 123"
                step="0.01"
                required
              />
            </ion-item>
            <ion-item
              v-if="request.section === 'projects' && !projectProduct"
              :key="`project-product-${request.type}`"
              :disabled="!selectedProduct.id"
            >
              <ion-label position="stacked">
                Locatie
              </ion-label>
              <ion-select
                v-model="request.location_id"
                placeholder="Kies een locatie"
                ok-text="Kiezen"
                cancel-text="Annuleren"
                required
              >
                <ion-select-option
                  v-for="(p, i) in (selectedProduct ? selectedProduct.project_products : [])"
                  :key="`product-${p.id}`"
                  :value="p.location.id"
                  :selected="i === 0"
                >
                  {{ p.location.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item
              v-else-if="request.section === 'agreements' && !projectProduct"
              :key="`location-${request.type}`"
              :disabled="!selectedProduct.id"
            >
              <ion-label position="stacked">
                Locatie
              </ion-label>
              <ion-select
                v-model="request.location_id"
                placeholder="Kies een locatie"
                ok-text="Kiezen"
                cancel-text="Annuleren"
                required
              >
                <ion-select-option
                  v-for="(l, i) in locations"
                  :key="`location-${l.id}`"
                  :value="l.id"
                  :selected="i === 0"
                >
                  {{ l.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                Datum en tijd
              </ion-label>
              <ion-datetime
                ref="datetime"
                v-model="request.datetime"
                :disabled="!selectedProduct.id"
                hour-values="7,8,9,10,11,12,13,14,15,16"
                :min="dateInterval()"
                cancel-text="Annuleren"
                done-text="Gereed"
                placeholder="Kies een datum en tijd"
                display-format="D MMM YYYY HH:mm"
                display-timezone="Europe/Amsterdam"
                @ionChange="dateChanged = true"
              />
            </ion-item>
            <ion-item>
              <ion-label position="stacked">
                Aanvullende informatie (optioneel)
              </ion-label>
              <ion-textarea v-model="request.remark" />
            </ion-item>
            <ion-item
              v-if="item.id"
              lines="none"
              class="ion-margin-vertical"
            >
              <ion-toggle
                slot="start"
                v-model="shouldDelete"
                color="danger"
              />
              <ion-label class="text-sm">
                Afroep annuleren
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card>
      </form>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          v-if="shouldDelete"
          color="danger"
          expand="block"
          @click="cancel"
        >
          Annuleer verzoek versturen
        </ion-button>
        <ion-button
          v-else-if="$refs.form"
          color="secondary"
          expand="block"
          :disabled="!valid"
          @click="validate($refs.form) && submit()"
        >
          <ion-icon
            v-if="!request.id"
            slot="start"
            name="add-circle-outline"
          />
          {{ request.id ? 'Wijziging indienen' : 'Product afroepen' }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonDatetime,
  IonTextarea,
  IonToggle,
  IonFooter,
} from '@ionic/vue';
import Projects from '@/modals/Projects.vue';
import Products from '@/modals/Products.vue';
import ProjectProducts from '@/modals/ProjectProducts.vue';
import ModalMixin from '@/mixins/ModalMixin';
import ToastMixin from '@/mixins/ToastMixin';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonList,
    IonItem,
    IonRadioGroup,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonDatetime,
    IonTextarea,
    IonToggle,
    IonFooter,
  },
  mixins: [ModalMixin, ToastMixin, ValidationMixin],
  props: {
    project: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    projectProduct: {
      type: Number,
      required: false,
      default: null,
    },
    location: {
      type: Number,
      required: false,
      default: null,
    },
    product: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      shouldDelete: false,
      agreement: {},
      selectedProduct: this.product,
      locations: [],
      request: {
        id: null,
        section: 'projects',
        project: this.project,
        type: this.type,
        unit: 1,
        total_cubic_meters: null,
        product_id: this.projectProduct,
        total_weight: null,
        location_id: this.location,
        datetime: null,
        remark: null,
        ...this.item,
      },
      dateChanged: false,
    };
  },
  computed: {
    projectId() {
      let projectId;

      if (this.request.section === 'agreements') {
        projectId = this.agreement.id || 0;
      } else {
        projectId = this.request.project.id;
      }

      return projectId;
    },
    valid() {
      return this.request.type && this.selectedProduct.id && this.request.datetime;
    },
  },
  watch: {
    project: {
      handler(value) {
        this.request.project = value;
      },
    },
    type: {
      handler(value) {
        this.request.type = value;
      },
    },
    projectProduct: {
      handler(value) {
        if (value) {
          this.request.product_id = value;
        }
      },
    },
    'request.section': {
      handler() {
        this.request.type = null;
        this.reset();
      },
    },
    'request.total_cubic_meters': {
      handler(value) {
        if (this.product.cubic_factor > 0) {
          this.request.total_weight = this.product.cubic_factor * value;
        }
      },
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    /**
     * Open projects modal.
     */
    async openProjects() {
      const result = await this.openModal(Projects, {
        section: this.request.section,
        value: this.request.project,
        multiple: false,
      }, true);

      if (result && result.id) {
        this.request.project = result;
      }
    },
    /**
     * Open project products modal.
     */
    async openProjectProducts() {
      const result = await this.openModal(ProjectProducts, {
        value: this.selectedProduct,
        projectId: this.projectId,
        type: this.request.type,
      }, true);

      if (result && result.id) {
        this.selectedProduct = result;
      }
    },
    /**
     * Open products modal.
     */
    async openProducts() {
      const result = await this.openModal(Products, {
        value: this.selectedProduct,
        projectId: this.projectId,
        type: this.request.type,
      }, true);

      if (result && result.id) {
        this.selectedProduct = result;
        this.locations = result.locations;
      }
    },
    /**
     * Fetch.
     *
     * @return {Object}
     */
    async fetch() {
      try {
        const response = await this.$http.get('auth/agreement');
        this.agreement = response.data;
      /* c8 ignore next 3 */
      } catch {
        // agreement may not exist, do nothing
      }
    },
    /**
     * Change type.
     */
    changeType() {
      this.reset();
    },
    /**
     * Reset state.
     */
    reset() {
      this.request.product_id = null;
      this.selectedProduct = {};
      this.locations.splice(0, this.locations.length);
    },
    /**
     * Submit request.
     */
    async submit() {
      const request = {
        product_id: this.selectedProduct.id,
        location_id: this.request.location_id,
        type: this.request.type,
        total_weight: this.request.total_weight,
        remark: this.request.remark || '',
      };

      if (this.dateChanged) {
        request.datetime = this.request.datetime;
      }

      try {
        let result;
        if (this.request.id) {
          result = await this.$http.patch(`projects/${this.projectId}/products/${this.request.product_id}/request/${this.request.id}`, request);
        } else {
          result = await this.$http.post(`projects/${this.projectId}/request`, request);
        }
        await this.showSuccessToast('Verzenden gelukt');
        await this.closeModal(result);
      } catch (e) {
        await this.showErrorToast('Verzenden mislukt');
      }
    },
    /**
     * Cancel request.
     */
    async cancel() {
      const request = {
        remark: this.request.remark,
      };
      try {
        const result = await this.$http.post(`projects/${this.projectId}/products/${this.request.product_id}/request/${this.request.id}/abort`, request);
        await this.showSuccessToast('Verzoek verzonden');
        await this.closeModal(result.data);
      } catch (e) {
        await this.showErrorToast('Verzenden mislukt');
      }
    },
    /**
     * Calculate date interval.
     *
     * @return  {Date}
     */
    dateInterval() {
      let date = new Date();
      const product = this.selectedProduct;
      if (this.request.type === 'outgoing' && product.id) {
        date = new Date(date.getTime() + (1000 * 60 * 60 * 24 * product.request_interval));
      }
      return date.toISOString().split('T').shift();
    },
  },
};
</script>
