import { modalController } from '@ionic/vue';

/**
 * Modal mixin.
 */
export default {
  methods: {
    /**
     * Open modal.
     *
     * @param {Object} component
     * @param {Object} props
     * @param {Boolean} stacked
     *
     * @return {Mixed}
     */
    async openModal(component, props = {}, stacked = false) {
      // Create modal
      const modal = await modalController.create({
        component,
        componentProps: {
          ...props,
        },
        presentingElement: stacked ? this.$el : undefined,
        swipeToClose: stacked,
      });

      // Present modal
      await modal.present();

      // Return data
      const result = await modal.onDidDismiss();
      return result.data;
    },
    /**
     * Close modal.
     *
     * @param {Mixed} data
     */
    async closeModal(data = null) {
      await modalController.dismiss(data);
    },
  },
};
