<template>
  <div>
    <ion-item
      lines="full"
      class="ion-no-padding ion-margin-top"
      @click="expanded = !expanded"
    >
      <ion-icon
        slot="start"
        :name="expanded ? 'chevron-up-circle' : 'chevron-down-circle'"
        color="black"
      />
      <ion-label>
        <ion-card-title>Afroepen</ion-card-title>
      </ion-label>
      <ion-chip
        slot="end"
        color="secondary"
        class="ion-no-margin"
      >
        {{ requests.length }}
      </ion-chip>
    </ion-item>
    <ion-item-group v-if="expanded">
      <ion-item
        v-for="request in requests"
        :key="`product-request-${request.id}`"
        class="ion-no-padding text-sm"
        button
        lines="full"
        :detail="(request.status === 'accepted' && !inPast(request.datetime)).toString()"
        @click="editRequest(request, product)"
      >
        <ion-icon
          v-if="request.status === 'accepted' && inPast(request.datetime)"
          slot="start"
          name="checkmark-circle-outline"
          color="dark"
        />
        <ion-icon
          v-else-if="request.status === 'accepted'"
          slot="start"
          name="create-outline"
        />
        <ion-icon
          v-else-if="request.status === 'open'"
          slot="start"
          name="time-outline"
          color="dark"
        />
        <ion-icon
          v-else-if="request.status === 'canceled'"
          slot="start"
          name="close"
          color="dark"
        />
        <ion-label :color="request.status !== 'open' ? 'dark' : ''">
          {{ formatDateTime(request.datetime, {
            weekday: 'short',
            minute: 'numeric',
            hour: 'numeric',
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }) }}
        </ion-label>
        <ion-note
          slot="end"
          class="ion-no-margin"
          :color="request.status === 'open' ? 'dark' : 'dark'"
        >
          {{ formatNumber(request.total_weight) }} ton
        </ion-note>
      </ion-item>
      <ion-item
        v-if="type === 'outgoing'"
        class="ion-no-padding ion-align-items-start text-sm"
        button
        lines="full"
        detail="false"
      >
        <ion-icon
          slot="start"
          name="time-outline"
          color="dark"
        />
        <ion-note class="padding-y">
          De afroepen met dit icoon zijn in behandeling.
          U kunt deze alleen wijzigen door telefonisch contact op te nemen met de locatie.
        </ion-note>
      </ion-item>
    </ion-item-group>
  </div>
</template>

<script>
import {
  IonCardTitle,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonNote,
  IonChip,
  IonIcon,
} from '@ionic/vue';
import Request from '@/modals/Request.vue';
import IntlMixin from '@/mixins/IntlMixin';
import ModalMixin from '@/mixins/ModalMixin';

export default {
  components: {
    IonCardTitle,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonNote,
    IonChip,
    IonIcon,
  },
  mixins: [IntlMixin, ModalMixin],
  props: {
    requests: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated'],
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    /**
     * Open edit request modal.
     *
     * @param {Object} request
     * @param {Object} product
     */
    async editRequest(request, product) {
      if (request.status === 'accepted' && !this.inPast(request.datetime)) {
        await this.openModal(Request, {
          project: {
            id: request.product.project_id,
          },
          projectProduct: request.product_id,
          type: this.type,
          item: request,
          product,
        });
        this.$emit('updated');
      }
    },
    /**
     * Check if date is in past.
     *
     * @param {String} date
     *
     * @return {Boolean}
     */
    inPast(date) {
      return new Date() > new Date(date);
    },
  },
};
</script>
