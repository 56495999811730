import { createApp } from 'vue';

import '@/registerServiceWorker';
import ionic from '@/plugins/ionic';
import router from '@/plugins/router';
import { VueAxios, axios } from '@/plugins/axios';
import auth from '@/plugins/auth';
import analytics from '@/plugins/analytics';

import App from '@/App.vue';

const app = createApp(App)
  .use(ionic)
  .use(router)
  .use(VueAxios, axios)
  .use(auth)
  .use(analytics, {
    property: {
      id: 'G-RC4RQ5DKR4',
      params: {
        anonymize_ip: true,
      },
    },
  }, router);

router.isReady().then(() => {
  app.mount('#app');
});
