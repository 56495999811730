<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Certificaten</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <template v-if="certificates.length">
        <section class="ion-padding">
          <ion-card-subtitle>{{ project.reference }}</ion-card-subtitle>
          <ion-card-title>{{ project.name }}</ion-card-title>
        </section>
        <ion-card class="ion-no-margin ion-margin-horizontal">
          <ion-card-content class="ion-no-padding">
            <ion-list class="ion-no-padding">
              <ion-item
                class="text-sm"
                lines="full"
              >
                <ion-label color="dark">
                  Certificaten voor afgenomen producten
                </ion-label>
              </ion-item>
              <ion-item
                v-for="product in products"
                :key="`product-${product.id}`"
                lines="full"
              >
                <ion-label>{{ product.product.name }}</ion-label>
                <ion-buttons
                  v-if="product.certificate_path"
                  slot="end"
                  class="ion-no-margin"
                >
                  <ion-button @click="mail(product)">
                    <ion-icon
                      slot="icon-only"
                      name="mail-outline"
                      size="small"
                    />
                  </ion-button>
                  <ion-button @click="download(product)">
                    <ion-icon
                      slot="icon-only"
                      name="cloud-download-outline"
                      size="small"
                    />
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <template v-else-if="products">
        <section class="ion-padding-horizontal">
          <p class="text-sm">
            Certificaten nodig voor dit project? Vraag ze dan aan.
            Je ontvangt een melding zodra ze beschikbaar zijn.
          </p>
        </section>
        <ion-card class="ion-margin-horizontal">
          <ion-card-content class="ion-no-padding">
            <ion-item lines="none">
              <ion-label position="stacked">
                Aanvullende informatie (optioneel)
              </ion-label>
              <ion-textarea v-model="remark" />
            </ion-item>
          </ion-card-content>
        </ion-card>
      </template>
    </ion-content>
    <ion-footer v-if="products && !certificates.length">
      <ion-toolbar>
        <ion-button
          color="secondary"
          expand="block"
          @click="request"
        >
          <ion-icon
            slot="start"
            name="ribbon-outline"
          />
          Certificaten aanvragen
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonFooter,
} from '@ionic/vue';
import ModalMixin from '@/mixins/ModalMixin';
import ToastMixin from '@/mixins/ToastMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonFooter,
  },
  mixins: [ModalMixin, ToastMixin],
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: null,
      remark: '',
    };
  },
  computed: {
    certificates() {
      return this.products && this.products.length ? this.products
        .flatMap((product) => (product.certificate_path ? [product.certificate_name] : [])) : [];
    },
  },
  watch: {
    project: {
      async handler(value) {
        try {
          const result = await this.$http.get(`projects/${value.id}/certificates`);
          this.products = result.data;
        } catch {
          await this.showErrorToast('Certificaten ophalen mislukt');
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * Request certificates.
     */
    async request() {
      try {
        await this.$http.post(`projects/${this.project.id}/certificates`, {
          remark: this.remark,
        });
        await this.showSuccessToast('De certificaten zijn aangevraagd');
        await this.closeModal();
      } catch {
        await this.showErrorToast('Certificaten aanvragen mislukt');
      }
    },
    /**
     * Mail certificate.
     *
     * @param {Object} product
     */
    async mail(product) {
      try {
        await this.$http.get(`projects/${this.project.id}/certificates/${product.id}/mail`);
        await this.showSuccessToast('Het certificaat is naar uw e-mailadres verzonden');
      } catch {
        await this.showErrorToast('Certificaat verzenden mislukt');
      }
    },
    /**
     * Download certificate.
     *
     * @param {Object} product
     */
    async download(product) {
      try {
        const result = await this.$http.get(`projects/${this.project.id}/certificates/${product.id}/download`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(result.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = product.certificate_name;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch {
        await this.showErrorToast('Certificaat downloaden mislukt');
      }
    },
  },
};
</script>

<style scoped>
ion-list {
  background: none !important;
}

ion-item,
ion-button {
  --background: none;
}
</style>
