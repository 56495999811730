import Login from '@/views/Login.vue';
import Tabs from '@/views/Tabs.vue';
import Projects from '@/views/Projects.vue';
import Project from '@/views/Project.vue';
import ProductWeighings from '@/views/ProductWeighings.vue';
import Weighings from '@/views/Weighings.vue';
import Contact from '@/views/Contact.vue';
import Profile from '@/views/Profile.vue';
import User from '@/views/User.vue';

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false,
    },
    component: Login,
  },
  {
    path: '/',
    meta: {
      auth: true,
    },
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'projecten',
      },
      {
        path: '/projecten',
        name: 'projects',
        meta: {
          auth: true,
        },
        component: Projects,
      },
      {
        path: '/projecten/:id',
        name: 'project',
        meta: {
          auth: true,
        },
        component: Project,
      },
      {
        path: '/projecten/:project/:product/wegingen',
        name: 'project-weighing',
        meta: {
          auth: true,
        },
        component: ProductWeighings,
        props: () => ({ projectDetails: true }),
      },
      {
        path: '/wegingen',
        name: 'weighings',
        meta: {
          auth: true,
        },
        component: Weighings,
      },
      {
        path: '/wegingen/:project/:product/wegingen',
        name: 'weighing',
        meta: {
          auth: true,
        },
        component: ProductWeighings,
        props: () => ({ projectDetails: false }),
      },
      {
        path: '/contact',
        name: 'contact',
        meta: {
          auth: true,
        },
        component: Contact,
      },
      {
        path: '/profiel',
        name: 'profile',
        meta: {
          auth: true,
        },
        component: Profile,
      },
      {
        path: '/profiel/:id',
        name: 'user',
        meta: {
          auth: true,
        },
        component: User,
      },
    ],
  },
  {
    path: '/:path(.*)*',
    name: 'notfound',
    redirect: '/',
  },
];
