<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Afrondverzoek indienen</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <section class="ion-padding-horizontal">
        <p class="text-sm">
          <ion-text color="dark">
            Dien een verzoek bij 2R in om dit project af te ronden.
            Je ontvangt een melding zodra het verzoek is behandeld.
          </ion-text>
        </p>
      </section>
      <ion-card>
        <form>
          <ion-list lines="inset">
            <ion-item lines="none">
              <ion-label
                position="stacked"
                class="ion-text-wrap"
              >
                Opmerking (optioneel)
              </ion-label>
              <ion-textarea
                v-model="comment"
                auto-grow="true"
                placeholder=""
              />
            </ion-item>
          </ion-list>
        </form>
      </ion-card>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          color="secondary"
          expand="block"
          @click="submit"
        >
          <ion-icon
            slot="start"
            name="archive-outline"
          />
          Afrondverzoek indienen
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonText,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonFooter,
} from '@ionic/vue';
import ModalMixin from '@/mixins/ModalMixin';
import ToastMixin from '@/mixins/ToastMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonText,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonFooter,
  },
  mixins: [
    ModalMixin,
    ToastMixin,
  ],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      comment: null,
    };
  },
  methods: {
    /**
     * Submit request.
     */
    async submit() {
      const request = {
        comment: this.comment,
      };
      try {
        await this.$http.post(`projects/${this.id}/archive-request`, request);
        await this.showSuccessToast('Verzenden gelukt');
        await this.closeModal();
      } catch {
        await this.showErrorToast('Verzenden mislukt');
      }
    },
  },
};
</script>
