<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-title>Contact</ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <ion-card>
        <form>
          <ion-list lines="none">
            <ion-item>
              <ion-label>
                Locatie
              </ion-label>
              <ion-select v-model="location">
                <ion-select-option
                  v-for="loc in locations"
                  :key="`location-${loc.id}`"
                  :value="loc.id"
                >
                  {{ loc.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </form>
      </ion-card>

      <location
        v-if="location"
        :location="locations.find((loc) => loc.id === +location)"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/vue';
import Location from '@/components/Location.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonThumbnail,
    IonContent,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    Location,
  },
  data() {
    return {
      locations: [],
      location: null,
    };
  },
  async created() {
    const result = await this.$http.get('locations');
    this.locations = result.data;

    // Set default location
    this.location = this.locations[0].id;
  },
};
</script>
