/**
 * Show mixin.
 *
 * @param   {Function}  endpoint
 * @param   {String} param
 *
 * @return  {Object}
 */
const ShowMixin = (endpoint, param = '$route.params.id') => ({
  data() {
    return {
      item: {},
      params: {},
    };
  },
  watch: {
    [param]: {
      handler(id) {
        this.id = id;
        this.onUpdate(id);
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * Update callback.
     *
     * @param {Number} id
     */
    async onUpdate(id) {
      const uri = endpoint(id);
      try {
        const response = await this.$http.get(uri, this.params);
        this.item = response.data;
      } catch (error) {
        this.onShowError(error);
      }
    },
    /**
     * On show error handler.
     */
    /* c8 ignore next 3 */
    onShowError() {
      // placeholder
    },
  },
});

export default ShowMixin;
