<template>
  <ion-progress-bar
    :color="progress >= 1 ? 'warning' : 'success'"
    :value="progress"
  />
</template>

<script>
import { IonProgressBar } from '@ionic/vue';

export default {
  components: {
    IonProgressBar,
  },
  props: {
    used: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    progress() {
      if (this.total === 0) {
        return 0;
      }
      const amount = Math.min(this.used, this.total);
      return amount / this.total;
    },
  },
};
</script>

<style scoped>
ion-progress-bar {
  --buffer-background: #fff;
}

ion-card {
  ion-progress-bar {
    --buffer-background: #ece9e3;
  }
}
</style>
