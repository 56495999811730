/**
 * Validation mixin.
 */
export default {
  methods: {
    /**
     * Validate form.
     *
     * @param {Object} form
     *
     * @return {Boolean}
     */
    validate(form) {
      /* c8 ignore next 4 */
      if (!form.checkValidity()) {
        form.reportValidity();
        return false;
      }
      return true;
    },
  },
};
