<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-title>Losse wegingen</ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh"
      >
        <ion-refresher-content />
      </ion-refresher>

      <section
        v-if="no_project"
        class="ion-padding"
      >
        <ion-text color="dark">
          Er zijn nog geen losse wegingen
        </ion-text>
      </section>

      <template v-else>
        <section class="section-light ion-padding">
          <ion-text color="dark">
            Hier worden alle wegingen zonder project - dus volgens jaarafspraken - getoond.
          </ion-text>
          <ion-list class="ion-margin-top ion-margin-bottom">
            <ion-item
              class="ion-no-padding text-sm"
              lines="full"
            >
              <img
                alt="outgoing"
                src="/img/icon-outgoing.svg"
                class="ion-margin-end"
              >
              <ion-label class="ion-no-margin">
                <b>Totaal gehaald</b>
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="black"
              >
                <b><balance :used="item.stats.outgoing" /></b>
              </ion-note>
            </ion-item>
            <ion-item
              class="ion-no-padding text-sm"
              lines="none"
            >
              <img
                alt="incoming"
                src="/img/icon-incoming.svg"
                class="ion-margin-end"
              >
              <ion-label class="ion-no-margin">
                <b>Totaal gestort</b>
              </ion-label>
              <ion-note
                slot="end"
                class="ion-no-margin"
                color="black"
              >
                <b><balance :used="item.stats.incoming" /></b>
              </ion-note>
            </ion-item>
            <ion-item
              class="ion-margin-top text-sm border-radius-top"
              lines="full"
              color="light"
            >
              <ion-label>Van</ion-label>
              <ion-datetime
                v-model="startDate"
                display-format="DD-MM-YYYY"
                placeholder="Selecteer datum"
              />
            </ion-item>
            <ion-item
              class="text-sm border-radius-bottom"
              lines="none"
              color="light"
            >
              <ion-label>Tot</ion-label>
              <ion-datetime
                v-model="endDate"
                display-format="DD-MM-YYYY"
                display-timezone="Europe/Amsterdam"
                placeholder="Selecteer datum"
              />
            </ion-item>
          </ion-list>
          <ion-button
            expand="block"
            color="light"
            class="ion-no-margin"
            @click="requestReport"
          >
            <ion-icon
              slot="start"
              name="stats-chart-outline"
            />
            Rapportage
          </ion-button>
        </section>

        <section class="ion-padding-bottom">
          <div class="segment">
            <ion-segment v-model="section">
              <ion-segment-button value="outgoing">
                <ion-label>Halen</ion-label>
              </ion-segment-button>
              <ion-segment-button value="incoming">
                <ion-label>Storten</ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>

          <project-products
            v-if="item.project.id"
            :id="item.project.id"
            :type="section"
            :start-date="startDate"
            :end-date="endDate"
            weighing-route="weighing"
            :requestable="true"
          />
        </section>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonDatetime,
  IonButton,
  IonIcon,
  IonSegment,
  IonSegmentButton,
} from '@ionic/vue';
import Balance from '@/components/Balance.vue';
import ShowMixin from '@/mixins/ShowMixin';
import ToastMixin from '@/mixins/ToastMixin';
import ProjectProducts from '@/components/ProjectProducts.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonThumbnail,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonDatetime,
    IonButton,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    Balance,
    ProjectProducts,
  },
  mixins: [
    ShowMixin(() => 'projects/agreement'),
    ToastMixin,
  ],
  data() {
    return {
      section: 'outgoing',
      startDate: `${new Date().getFullYear()}-01-01`,
      endDate: `${new Date().getFullYear()}-12-31`,
      item: {
        project: {},
        stats: {
          outgoing: 0,
          incoming: 0,
        },
      },
      no_project: false,
    };
  },
  methods: {
    /**
     * Refresh.
     *
     * @param {Event} e
     */
    async refresh(e) {
      await this.onUpdate();
      e.target.complete();
    },
    /**
     * Request report.
     */
    async requestReport() {
      try {
        await this.$http.post(`projects/${this.item.project.id}/report`, {
          startDate: this.startDate,
          endDate: this.endDate,
        });
        await this.showSuccessToast('Verzenden gelukt');
      } catch {
        await this.showErrorToast('Verzenden mislukt');
      }
    },
    async onShowError(error) {
      if (error.response.status === 404) {
        this.no_project = true;
      } else {
        await this.showErrorToast('Fout bij het laden van de gegevens');
      }
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: #bcb6ab;
}

.section-light {
  background: #ece9e3;
}

ion-list {
  background: none !important;
}

ion-item {
  --background: none;
}

ion-row {
  width: 100%;
}

.segment {
  position: sticky;
  z-index: 999;
  top: 0;
  background: #bcb6ab;
}

.ios {
  .segment {
    padding: 8px;

    ion-segment {
      --background: #646464;
    }

    ion-segment-button {
      --color: #fff;
      --color-checked: #000;
    }
  }

  ion-item {
    &.border-radius-top {
      --border-radius: 10px 10px 0 0;
    }

    &.border-radius-bottom {
      --border-radius: 0 0 10px 10px;
    }
  }
}

.md {
  ion-item {
    &.border-radius-top {
      --border-radius: 5px 5px 0 0;
    }

    &.border-radius-bottom {
      --border-radius: 0 0 5px 5px;
    }
  }
}
</style>
