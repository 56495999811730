/**
 * Intl mixin.
 */
const locale = 'nl';

export default {
  methods: {
    /**
     * Format datetime.
     *
     * @param {mixed} datetime
     * @param {Object} options
     */
    formatDateTime(datetime, options) {
      const intl = new Intl.DateTimeFormat(locale, {
        ...options,
      });
      return intl.format(typeof datetime === 'number' ? datetime : new Date(datetime));
    },
    /**
     * Format number.
     *
     * @param {Number} number
     * @param {Object} options
     */
    formatNumber(number, options) {
      const intl = new Intl.NumberFormat(locale, {
        ...options,
      });
      return intl.format(number);
    },
  },
};
