<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/profiel"
            text=""
            icon="arrow-back"
            color="secondary"
          />
        </ion-buttons>
        <ion-title>Gebruiker</ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <ion-card
        class="ion-margin"
        button
        @click="openUser"
      >
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-card-title v-if="item.id">
              {{ item.name }}
            </ion-card-title>
            <ion-skeleton-text
              v-else
              style="width: 90%;"
              animated
            />
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <p v-if="item.id">
            {{ item.email }}
          </p>
          <p v-else>
            <ion-skeleton-text
              style="width: 15%;"
              animated
            />
          </p>
          <p v-if="item.id">
            {{ item.phone }}
          </p>
          <p v-else>
            <ion-skeleton-text
              style="width: 20%;"
              animated
            />
          </p>
        </ion-card-content>
      </ion-card>

      <ion-row class="ion-margin-horizontal ion-padding-top ion-align-items-center">
        <ion-col class="ion-no-padding">
          <h4 class="ion-no-margin">
            Projecten
          </h4>
        </ion-col>
        <ion-col class="ion-text-end ion-no-padding">
          <ion-button
            :disabled="item.projects === null"
            class="ion-no-margin"
            color="secondary"
            @click="openProjects"
          >
            Beheren
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-card class="ion-margin ion-margin-horizontal">
        <ion-card-content class="ion-no-padding">
          <ion-list
            v-if="item.projects === null"
            class="ion-no-padding"
          >
            <ion-item
              v-for="index in 20"
              :key="`project-skeleton-${index}`"
              class="ion-no-padding"
            >
              <ion-label class="ion-text-wrap ion-padding-start ion-margin-vertical">
                <ion-card-subtitle>
                  <ion-skeleton-text
                    style="width: 25%;"
                    animated
                  />
                </ion-card-subtitle>
                <ion-skeleton-text
                  style="width: 90%;"
                  animated
                />
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            v-else-if="item.projects.length"
            class="ion-no-padding"
          >
            <ion-item
              v-for="(project, index) in item.projects"
              :key="`project-${project.id}`"
              :lines="index === (item.projects.length - 1) ? 'none' : 'full'"
              class="ion-no-padding"
            >
              <ion-label class="ion-text-wrap ion-padding-start ion-margin-vertical">
                <ion-card-subtitle>{{ project.reference }}</ion-card-subtitle>
                <b>{{ project.name }}</b>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            v-else
            lines="none"
          >
            <ion-item>Geen projecten</ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSkeletonText,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonCardSubtitle,
} from '@ionic/vue';
import ModalMixin from '@/mixins/ModalMixin';
import ShowMixin from '@/mixins/ShowMixin';
import ToastMixin from '@/mixins/ToastMixin';
import Projects from '@/modals/Projects.vue';
import User from '@/modals/User.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonThumbnail,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonSkeletonText,
    IonRow,
    IonCol,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonCardSubtitle,
  },
  mixins: [
    ModalMixin,
    ShowMixin((id) => `users/${id}`),
    ToastMixin,
  ],
  data() {
    return {
      item: {
        projects: null,
      },
    };
  },
  methods: {
    /**
     * Open user modal.
     */
    async openUser() {
      const result = await this.openModal(User, {
        user: this.item,
      });

      if (result.deleted_at) {
        this.$router.go(-1);
        return;
      }

      if (result.id) {
        Object.assign(this.item, result);
      }
    },
    /**
     * Open projects modal.
     */
    async openProjects() {
      const result = await this.openModal(Projects, {
        value: [...this.item.projects],
        multiple: true,
        callback: (projects) => this.$http.post(`users/${this.item.id}/projects`, {
          projects: projects.map((project) => project.id),
        }),
      });

      if (Array.isArray(result)) {
        this.item.projects = result;
        await this.showSuccessToast('Projecten opgeslagen');
      }
    },
  },
};
</script>
