<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-title>Projecten</ion-title>
        <ion-thumbnail
          slot="end"
          collapse="true"
          @click="$router.push({ name: 'home' })"
        >
          <img
            alt="Twee “R”"
            src="/img/logo.svg"
          >
        </ion-thumbnail>
      </ion-toolbar>
      <ion-toolbar>
        <ion-segment v-model="params.section">
          <ion-segment-button value="projects">
            <ion-label>Projecten</ion-label>
          </ion-segment-button>
          <ion-segment-button value="archive">
            <ion-label>Archief</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <ion-searchbar
        v-model="params.search"
        inputmode="search"
        placeholder="Zoek naar projecten"
      />

      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh"
      >
        <ion-refresher-content />
      </ion-refresher>

      <ion-card class="ion-no-margin ion-margin-horizontal">
        <ion-card-content class="ion-no-padding">
          <ion-list
            v-if="items === null"
            class="ion-no-padding"
          >
            <ion-item
              v-for="index in 20"
              :key="`project-skeleton-${index}`"
              class="ion-no-padding"
            >
              <ion-label class="ion-text-wrap ion-padding-start ion-margin-vertical">
                <p>
                  <ion-skeleton-text
                    style="width: 25%;"
                    animated
                  />
                </p>
                <h2>
                  <ion-skeleton-text
                    style="width: 90%;"
                    animated
                  />
                  <ion-skeleton-text
                    style="width: 80%;"
                    animated
                  />
                </h2>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            v-else-if="items.length"
            class="ion-no-padding"
          >
            <ion-item
              v-for="(project, index) in items"
              :key="`project-${project.id}`"
              :lines="index === (items.length - 1) ? 'none' : 'full'"
              detail="false"
              class="ion-no-padding"
              button
              @click="$router.push({ name: 'project', params: { id: project.id } })"
            >
              <ion-label class="ion-text-wrap ion-padding-start ion-margin-vertical">
                <ion-card-subtitle>{{ project.reference }}</ion-card-subtitle>
                <ion-card-title>{{ project.name }}</ion-card-title>
              </ion-label>
              <ion-icon
                v-if="project.unperceived_comments > 0"
                slot="end"
                name="alert-circle"
                size="small"
                class="ion-no-margin"
                color="warning"
              />
            </ion-item>
          </ion-list>
          <ion-list
            v-else
            lines="none"
          >
            <ion-item>Geen projecten</ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-infinite-scroll @ionInfinite="load">
        <ion-infinite-scroll-content />
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonContent,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonSkeletonText,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';
import DataMixin from '@/mixins/DataMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonThumbnail,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonContent,
    IonSearchbar,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonSkeletonText,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  mixins: [DataMixin],
  data() {
    return {
      endpoint: 'projects',
      params: {
        section: 'projects',
        search: null,
      },
    };
  },
};
</script>
