<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Selecteer project{{ multiple ? '(en)' : '' }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <ion-searchbar
        v-model="params.search"
        inputmode="search"
        placeholder="Zoek naar projecten"
      />

      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh"
      >
        <ion-refresher-content />
      </ion-refresher>

      <ion-card class="ion-no-margin ion-margin-horizontal">
        <ion-card-content class="ion-no-padding">
          <ion-list
            v-if="items === null"
            class="ion-no-padding"
          >
            <ion-item
              v-for="index in 20"
              :key="`project-skeleton-${index}`"
              class="ion-no-padding"
            >
              <ion-avatar
                slot="start"
                class="ion-margin-start"
                style="width: 26px; height: 26px;"
              >
                <ion-skeleton-text
                  v-if="multiple"
                  animated
                />
              </ion-avatar>
              <ion-label class="ion-text-wrap ion-margin-vertical">
                <ion-card-subtitle>
                  <ion-skeleton-text
                    style="width: 25%;"
                    animated
                  />
                </ion-card-subtitle>
                <b>
                  <ion-skeleton-text
                    style="width: 90%;"
                    animated
                  />
                  <ion-skeleton-text
                    style="width: 80%;"
                    animated
                  />
                </b>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-list
            v-else-if="items.length"
            class="ion-no-padding"
          >
            <ion-radio-group
              :value="value ? value.id : null"
              @ionChange="onChange"
            >
              <ion-item
                v-for="(project, index) in items"
                :key="`project-${project.id}`"
                :lines="index === (items.length - 1) ? 'none' : 'full'"
                class="ion-no-padding"
              >
                <component
                  :is="`ion-${multiple ? 'checkbox' : 'radio'}`"
                  slot="start"
                  :value="project.id"
                  :checked="Array.isArray(value) && value.map((v) => v.id).includes(project.id)"
                  color="success"
                  class="ion-margin-start"
                />
                <ion-label class="ion-text-wrap ion-margin-vertical">
                  <ion-card-subtitle>{{ project.reference }}</ion-card-subtitle>
                  <b>{{ project.name }}</b>
                </ion-label>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <ion-list
            v-else
            lines="none"
          >
            <ion-item>Geen projecten</ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-infinite-scroll @ionInfinite="load">
        <ion-infinite-scroll-content />
      </ion-infinite-scroll>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          color="secondary"
          expand="block"
          @click="saveProjects"
        >
          Opslaan
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonAvatar,
  IonSkeletonText,
  IonLabel,
  IonCardSubtitle,
  IonRadioGroup,
  IonCheckbox,
  IonRadio,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFooter,
} from '@ionic/vue';
import DataMixin from '@/mixins/DataMixin';
import ModalMixin from '@/mixins/ModalMixin';
import ToastMixin from '@/mixins/ToastMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonSearchbar,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonAvatar,
    IonSkeletonText,
    IonLabel,
    IonCardSubtitle,
    IonRadioGroup,
    IonCheckbox,
    IonRadio,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonFooter,
  },
  mixins: [DataMixin, ModalMixin, ToastMixin],
  props: {
    value: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    section: {
      type: String,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    callback: {
      type: Function,
      required: false,
      default: (projects) => ({
        data: projects,
      }),
    },
  },
  data() {
    return {
      endpoint: 'projects',
      params: {
        section: this.section,
        search: null,
      },
      checked: {},
      result: this.value,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.result = value;
      },
    },
    section: {
      handler(value) {
        this.params.section = value;
      },
    },
  },
  methods: {
    /**
     * Handle value values on change.
     *
     * @param {Event} event
     */
    onChange(event) {
      const value = this.items.find((item) => item.id === +event.detail.value);

      if (this.multiple) {
        if (event.detail.checked) {
          this.result.push(value);
        } else {
          this.result.splice(this.result.findIndex((item) => item.id === value.id), 1);
        }
      } else {
        this.result = value;
      }
    },
    /**
     * Save projects.
     */
    async saveProjects() {
      try {
        const result = await this.callback(this.result);
        await this.closeModal(result.data);
      } catch (e) {
        await this.showErrorToast('Opslaan mislukt');
      }
    },
  },
};
</script>
