<template>
  <ion-page>
    <ion-header translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              name="close"
              color="secondary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>Rapportage</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true">
      <section class="ion-padding-horizontal">
        <p class="text-sm">
          <ion-text color="dark">
            Voer een begin- en einddatum in.
            Je ontvangt de rapportage op je e-mailadres.
          </ion-text>
        </p>
      </section>
      <ion-card>
        <form>
          <ion-list lines="full">
            <ion-item>
              <ion-label>Begindatum</ion-label>
              <ion-datetime
                :value="startDate"
                :min="new Date(project.created_at).toISOString()"
                :max="new Date().toISOString()"
                cancel-text="Annuleren"
                done-text="Gereed"
                placeholder="Kies een datum"
                @ionChange="setMinEndDate"
              />
            </ion-item>
            <ion-item lines="none">
              <ion-label>Einddatum</ion-label>
              <ion-datetime
                :value="endDate"
                :min="minEndDate.toISOString()"
                :max="new Date().toISOString()"
                cancel-text="Annuleren"
                done-text="Gereed"
                placeholder="Kies een datum"
                @ionChange="endDate = new Date($event)"
              />
            </ion-item>
          </ion-list>
        </form>
      </ion-card>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          color="secondary"
          expand="block"
          @click="submit"
        >
          <ion-icon
            slot="start"
            name="stats-chart-outline"
          />
          Rapportage versturen
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonText,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonFooter,
} from '@ionic/vue';
import ModalMixin from '@/mixins/ModalMixin';
import ToastMixin from '@/mixins/ToastMixin';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonText,
    IonCard,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonFooter,
  },
  mixins: [
    ModalMixin,
    ToastMixin,
  ],
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    const created = new Date(this.project.created_at);
    return {
      startDate: created,
      endDate: new Date(),
      minEndDate: created,
    };
  },
  methods: {
    /**
     * Set minimum end date.
     *
     * @param {String} startDate
     */
    setMinEndDate(startDate) {
      this.startDate = new Date(startDate);
      const dates = [
        this.startDate,
        new Date(this.project.created_at),
      ];
      const maxDate = Math.max.apply(null, dates);
      this.minEndDate = new Date(maxDate);
    },
    /**
     * Submit form.
     */
    async submit() {
      const dateOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
      };

      try {
        await this.$http.post(`projects/${this.project.id}/report`, {
          startDate: this.startDate.toLocaleDateString('nl-NL', dateOptions),
          endDate: this.endDate.toLocaleDateString('nl-NL', dateOptions),
        });
        await this.showSuccessToast('Verzenden gelukt');
        await this.closeModal();
      } catch {
        await this.showErrorToast('Verzenden mislukt');
      }
    },
  },
};
</script>
