<template>
  <span v-if="!used && total === 0">
    nader te bepalen
  </span>
  <span v-else-if="used && total">
    {{ formatNumber(used) }}/{{ formatNumber(total) }} ton
  </span>
  <span v-else-if="used && total === null">
    {{ formatNumber(used) }} ton
  </span>
  <span v-else-if="used && total === 0">
    {{ formatNumber(used) }} ton/n.t.b
  </span>
  <span v-else-if="!used && total">
    0/{{ formatNumber(total) }} ton
  </span>
</template>

<script>
import IntlMixin from '@/mixins/IntlMixin';

export default {
  mixins: [IntlMixin],
  props: {
    used: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
