<template>
  <ion-app v-if="$auth.ready()">
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue';

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    onMounted(async () => {
      const isiOSSafari = navigator.userAgent.match(/(like Mac OS X|jsdom)/i) !== null;
      if (isiOSSafari && !navigator.standalone) {
        const toast = await toastController.create({
          header: 'Installeer deze app',
          message: 'Voeg toe aan uw beginscherm via <img src="/img/icon-pwa.svg" /> en kies "Zet op beginscherm".',
          buttons: [
            {
              text: 'Sluiten',
              role: 'cancel',
            },
          ],
        });
        await toast.present();
      }
    });
  },
});
</script>

<style>
:root {
  --ion-color-primary: #ff8a00;
  --ion-color-primary-rgb: 152, 148, 175;
  --ion-color-primary-contrast: #000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #de7600;
  --ion-color-primary-tint: #ff9924;
  --ion-color-secondary: #222;
  --ion-color-secondary-rgb: 34, 34, 34;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000;
  --ion-color-secondary-tint: #444;
  --ion-color-tertiary: #ece9e3;
  --ion-color-tertiary-rgb: 245, 245, 245;
  --ion-color-tertiary-contrast: #000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #bcb6ab;
  --ion-color-tertiary-tint: #fff;
  --ion-color-light: #fff;
  --ion-color-dark: #666;
  --ion-color-warning: #ff8a00;
  --ion-color-warning-rgb: 152, 148, 175;
  --ion-color-success: #7ed321;
  --ion-color-success-rgb: rgb(126 211 33);
}

ion-tab-bar {
  --background: #464646;
  --color: #fff;

  ion-icon {
    font-size: 25px;
  }
}

ion-header {
  ion-toolbar {
    --background: #ece9e3;
  }

  ion-thumbnail {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

ion-content {
  --background: #ece9e3;

  ion-toolbar {
    --background: #ece9e3 !important;
  }

  ion-searchbar {
    --background: #fff !important;
    --border-radius: 30px !important;
  }
}

ion-card-title {
  font-size: 18px;
}

ion-footer {
  ion-toolbar {
    --background: #ece9e3;
  }
}

ion-chip ion-label {
  overflow: visible !important;
}

.hydrated {
  visibility: visible;
}

.progress-bar {
  --min-height: 20px;
}

.text-sm {
  font-size: 15px !important;
}

.padding-y {
  padding: 8px 0;
}

.md {
  ion-segment-button {
    --color-checked: #000;
  }

  ion-content {
    ion-searchbar {
      padding: 16px;
    }
  }

  ion-button {
    --box-shadow: none;

    letter-spacing: 0;
    text-transform: none;
  }

  .toast-bottom {
    top: -56px;
  }
}

.ios {
  ion-header {
    ion-segment {
      --background: #bcb6ab;

      width: 100%;
      margin-top: 0;
      margin-right: 8px;
      margin-left: 8px;
    }

    ion-segment-button {
      --indicator-color: #fff;
    }

    ion-back-button {
      --margin-start: 8px;
      --icon-font-size: 24px;
    }
  }

  .toast-bottom {
    top: -50px;
  }
}
</style>
