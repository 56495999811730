import { createAuth } from '@websanova/vue-auth';
import axios from 'axios';
import VueAuthDriverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm';
import VueAuthDriverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm';
import VueAuthDriverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm';
import router from '@/plugins/router';

export default createAuth({
  plugins: {
    http: axios,
    router,
  },
  drivers: {
    auth: {
      request: VueAuthDriverAuthBearer.request,
      response(res) {
        return (res.data || {}).access_token;
      },
    },
    http: VueAuthDriverHttpAxios,
    router: VueAuthDriverRouterVueRouter,
  },
  options: {
    logoutData: {
      url: 'auth/logout',
      method: 'POST',
      redirect: '/login',
      makeRequest: true,
    },
    fetchData: {
      url: 'auth/profile',
      method: 'GET',
      enabled: true,
    },
    refreshData: {
      url: 'auth/refresh',
      method: 'POST',
      enabled: true,
      interval: 0,
    },
    parseUserData: (data) => data,
  },
});
