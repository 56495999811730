/**
 * Data mixin.
 */
export default {
  data() {
    return {
      endpoint: '',
      params: {},
      page: 0,
      items: null,
    };
  },
  watch: {
    page(value) {
      if (value === 1) {
        const content = document.querySelector('ion-content');
        if (content) {
          content.scrollToTop();
        }
      }
    },
    params: {
      async handler() {
        this.items = null;
        this.page = 0;
        this.items = await this.fetch();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /**
     * Fetch items.
     *
     * @return {Array}
     */
    async fetch() {
      this.page += 1;
      const request = await this.$http.get(this.endpoint, {
        params: {
          page: this.page,
          ...this.params,
        },
      });
      const response = request.data;

      this.page = response.current_page;

      return response.data;
    },
    /**
     * Pull to refresh.
     *
     * @param {Event} e
     */
    refresh(e) {
      setTimeout(async () => {
        this.page = 0;
        this.items = await this.fetch();

        e.target.complete();
      }, 1000);
    },
    /**
     * Load more items.
     *
     * @param {Event} e
     */
    async load(e) {
      const items = await this.fetch();
      this.items.push(...items);

      e.target.complete();
    },
  },
};
